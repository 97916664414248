@import "variables";
@import "~bulma/bulma";

body,
html {
    //overflow-y: auto;
    overflow: hidden;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

*,
*:before,
*:after {
    position: relative;
}

.intro-section {
    flex: 1 1 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        flex-shrink: 0;
        flex-grow: 0;
    }
}

.space-section {
    flex: 1.25 1 0px;
    overflow: hidden;
    background: linear-gradient(to top, rgba(#fff, 0.05) 0%, transparent 100%),
        url(../img/sun@3x.png) bottom no-repeat;
    background-size: contain;

    .asteroid {
        position: absolute;
        /* @keyframes duration | timing-function | delay |
        iteration-count | direction | fill-mode | play-state | name */
        animation: 10s linear asteroid;
        animation-iteration-count: infinite;

        $asteroidCount: 20;
        @for $n from 1 to $asteroidCount {
            $p: $n / $asteroidCount;
            &:nth-child(#{$n}n) {
                // lower is further away
                animation-delay: random() * -123s;
                bottom: random() * 100px - 30px;
                animation-duration: 40s / $p;
                width: $p * 100px + 20px;
            }
        }

        @keyframes asteroid {
            0% {
                transform: translateX(110vh) rotate(0deg);
            }
            100% {
                transform: translateX(-10vh) rotate(-360deg);
            }
        }
    }
    //img.sun {
    //    position: absolute;
    //    top: 0;
    //    bottom: 0;
    //    height: auto;
    //    width: auto;
    //    left: 50%;
    //    right: 10%;
    //}
}
